import React, { useState, useMemo } from 'react';
import { Card, Table, Button, Dropdown, Menu, Select, Space, Input } from 'antd';
import { RefreshCw, Search } from 'lucide-react';
import { EllipsisOutlined } from '@ant-design/icons';

const { Option } = Select;

const FanTokensCard = ({ duneData, loadingDuneData, theme, fetchDuneData, profileHandle }) => {
  const [selectedColumns, setSelectedColumns] = useState(['name', 'symbol']);
  const [searchText, setSearchText] = useState('');

  const renderHtml = (html) => {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  };

  const allColumns = useMemo(() => {
    if (duneData.length === 0) return [];
    return Object.keys(duneData[0]).map(key => ({
      title: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '),
      dataIndex: key,
      key: key,
      sorter: (a, b) => {
        if (typeof a[key] === 'number') {
          return a[key] - b[key];
        }
        return (a[key] || '').toString().localeCompare((b[key] || '').toString());
      },
      render: (text) => {
        if (key === 'airstack_link' || key === 'warpcast_link' || key === 'dune_link') {
          return renderHtml(text);
        }
        if (typeof text === 'number') {
          if (key.includes('price') || key.includes('market_cap') || key.includes('volume')) {
            return `$${text.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
          } else if (key.includes('change')) {
            return `${text.toFixed(2)}%`;
          } else {
            return text.toLocaleString();
          }
        }
        return text;
      },
    }));
  }, [duneData]);

  const filteredColumns = useMemo(() => {
    return allColumns.filter(col => selectedColumns.includes(col.key));
  }, [allColumns, selectedColumns]);

  const filteredData = useMemo(() => {
    if (!searchText) return duneData;
    return duneData.filter(record => 
      Object.values(record).some(value => 
        value && value.toString().toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [duneData, searchText]);

  const handleColumnChange = (selectedKeys) => {
    setSelectedColumns(selectedKeys);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const menu = (
    <Menu>
      <Menu.Item key="warpcastProfile">
        <a href={`https://warpcast.com/${profileHandle}`} target="_blank" rel="noopener noreferrer">
          Open Warpcast Profile
        </a>
      </Menu.Item>
      <Menu.Item key="castFrame">
        <a href={`https://warpcast.com/~/compose?text=${encodeURIComponent('Check out my Fan Tokens: [Your Fan Tokens Frame URL]')}`} target="_blank" rel="noopener noreferrer">
          Cast Fan Tokens Frame
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <Card
      title="Fan Tokens (Dune Analytics Data)"
      style={{ marginBottom: 20, background: theme.secondary }}
      extra={
        <Space>
          <Select
            mode="multiple"
            style={{ width: '300px' }}
            placeholder="Select columns"
            value={selectedColumns}
            onChange={handleColumnChange}
            maxTagCount="responsive"
          >
            {allColumns.map(col => (
              <Option key={col.key} value={col.key}>{col.title}</Option>
            ))}
          </Select>
          <Button
            icon={<RefreshCw size={16} />}
            onClick={fetchDuneData}
            loading={loadingDuneData}
          >
            Refresh
          </Button>
          <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
            <Button type="text" icon={<EllipsisOutlined />} />
          </Dropdown>
        </Space>
      }
    >
      <Space style={{ marginBottom: 16 }}>
        <Input
          placeholder="Search table"
          onChange={handleSearch}
          style={{ width: 200 }}
          prefix={<Search size={16} />}
        />
      </Space>
      <Table
        columns={filteredColumns}
        dataSource={filteredData}
        loading={loadingDuneData}
        pagination={{ pageSize: 10, showSizeChanger: true }}
        scroll={{ x: 'max-content' }}
        rowKey={(record, index) => index}
      />
    </Card>
  );
};

export default FanTokensCard;