import React from 'react';
import { Card, Row, Col, Statistic, Typography, Button, Dropdown, Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';

const { Text } = Typography;

const MoxieEarningsCard = ({ moxieEarnings, moxiePriceUSD, theme, profileHandle }) => {
  const menu = (
    <Menu>
      <Menu.Item key="warpcastProfile">
        <a href={`https://warpcast.com/${profileHandle}`} target="_blank" rel="noopener noreferrer">
          Open Warpcast Profile
        </a>
      </Menu.Item>
      <Menu.Item key="castFrame0">
        <a href={`https://warpcast.com/~/compose?text=${encodeURIComponent('Check out my Moxie Earnings: moxie-profile.vercel.app')}`} target="_blank" rel="noopener noreferrer">
          @sgniwder
        </a>
      </Menu.Item>
      <Menu.Item key="castFrame1">
        <a href={`https://warpcast.com/~/compose?text=${encodeURIComponent('Check out my Moxie Earnings: moxie.genyframe.xyz')}`} target="_blank" rel="noopener noreferrer">
          @compez.eth
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <Card
      title="Moxie Earnings"
      style={{ marginBottom: 20, background: theme.secondary }}
      extra={
        <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
          <Button type="text" icon={<EllipsisOutlined />} />
        </Dropdown>
      }
    >
      <Row gutter={16}>
        <Col span={8}>
          <Statistic
            title={<Text style={{ color: theme.text }}>Today</Text>}
            value={moxieEarnings.today?.allEarningsAmount || 0}
            precision={2}
            valueStyle={{ color: theme.highlight }}
            prefix="Ⓜ️"
            suffix={
              moxiePriceUSD ? (
                <Text style={{ fontSize: '0.8em', color: theme.textSecondary }}>
                  (≈ ${(moxieEarnings.today?.allEarningsAmount * moxiePriceUSD).toFixed(2)} USD)
                </Text>
              ) : ''
            }
          />
        </Col>
        <Col span={8}>
          <Statistic
            title={<Text style={{ color: theme.text }}>This Week</Text>}
            value={moxieEarnings.weekly?.allEarningsAmount || 0}
            precision={2}
            valueStyle={{ color: theme.highlight }}
            prefix="Ⓜ️"
            suffix={
              moxiePriceUSD ? (
                <Text style={{ fontSize: '0.8em', color: theme.textSecondary }}>
                  (≈ ${(moxieEarnings.weekly?.allEarningsAmount * moxiePriceUSD).toFixed(2)} USD)
                </Text>
              ) : ''
            }
          />
        </Col>
        <Col span={8}>
          <Statistic
            title={<Text style={{ color: theme.text }}>Lifetime</Text>}
            value={moxieEarnings.lifetime?.allEarningsAmount || 0}
            precision={2}
            valueStyle={{ color: theme.highlight }}
            prefix="Ⓜ️"
            suffix={
              moxiePriceUSD ? (
                <Text style={{ fontSize: '0.8em', color: theme.textSecondary }}>
                  (≈ ${(moxieEarnings.lifetime?.allEarningsAmount * moxiePriceUSD).toFixed(2)} USD)
                </Text>
              ) : ''
            }
          />
        </Col>
      </Row>
    </Card>
  );
};

export default MoxieEarningsCard;