import React, { useState, useEffect } from 'react';
import { Card, Table, Typography, Button, Dropdown, Menu, Statistic, Row, Col, Spin, message, Alert } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';

const { Text, Title } = Typography;

const DegenReportCard = ({ fid, connectedAddresses = [], theme, profileHandle }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [points, setPoints] = useState(0);
  const [tips, setTips] = useState([]);
  const [allowances, setAllowances] = useState({
    dailyAllowance: 0,
    remainingAllowance: 0
  });

  const dropdownMenu = (
    <Menu>
      <Menu.Item key="warpcastProfile">
        <a href={`https://warpcast.com/${profileHandle}`} target="_blank" rel="noopener noreferrer">
          Open Warpcast Profile
        </a>
      </Menu.Item>
      <Menu.Item key="castFrame">
        <a href={`https://warpcast.com/~/compose?text=${encodeURIComponent('Check out my Degen report')}`} target="_blank" rel="noopener noreferrer">
          Cast Degen Report
        </a>
      </Menu.Item>
    </Menu>
  );

  const tipColumns = [
    {
      title: 'Time',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (timestamp) => new Date(timestamp).toLocaleString(),
      sorter: (a, b) => new Date(b.timestamp) - new Date(a.timestamp),
      defaultSortOrder: 'descend'
    },
    {
      title: 'Amount',
      dataIndex: 'tip_amount',
      key: 'amount'
    },
    {
      title: 'Daily Total',
      dataIndex: 'rolling_daily_tip_amount',
      key: 'dailyTotal',
      render: (amount, record) => `${amount} / ${record.tip_allowance}`
    },
    {
      title: 'Type',
      dataIndex: 'tip_type',
      key: 'type',
      render: (type) => type.charAt(0).toUpperCase() + type.slice(1)
    },
    {
      title: 'Status',
      dataIndex: 'tip_status',
      key: 'status',
      render: (status) => status.charAt(0).toUpperCase() + status.slice(1)
    },
    {
      title: 'Cast',
      dataIndex: 'cast_hash',
      key: 'cast',
      render: (hash) => (
        <a href={`https://warpcast.com/~/cast/${hash.replace(/\\/g, '')}`} target="_blank" rel="noopener noreferrer">
          View Cast
        </a>
      )
    }
  ];

  const calculatePoints = (data, targetFid) => {
    if (!Array.isArray(data) || data.length === 0) return 0;
    
    const filteredData = data.filter(item => item.fid === targetFid);
    return filteredData.reduce((sum, item) => {
      const points = parseInt(item.points || 0, 10);
      return isNaN(points) ? sum : sum + points;
    }, 0);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!fid) {
        setLoading(false);
        return;
      }

      setLoading(true);
      setError(null);

      try {
        // Points queries
        const pointQueries = [
          {
            url: `https://api.degen.tips/airdrop2/current/points?fid=${fid}&offset=0&limit=50`,
            type: 'fid'
          },
          ...connectedAddresses.map(address => ({
            url: `https://api.degen.tips/airdrop2/current/points?wallet=${address}&offset=0&limit=50`,
            type: 'wallet'
          }))
        ];

        console.log('Executing points queries:', pointQueries);

        // Fetch points
        let totalPoints = 0;
        for (const query of pointQueries) {
          try {
            const response = await fetch(query.url);
            const data = await response.json();
            console.log(`Points data for ${query.type}:`, data);
            const pointsSum = calculatePoints(data, fid);
            console.log(`Points sum for ${query.type}:`, pointsSum);
            totalPoints += pointsSum;
          } catch (error) {
            console.error(`Failed to fetch points for ${query.type}:`, error);
          }
        }
        console.log('Final total points:', totalPoints);
        setPoints(totalPoints);

        // Fetch tips and extract allowance info
        try {
          const tipsUrl = `https://api.degen.tips/airdrop2/tips?fid=${fid}&offset=0&limit=50`;
          console.log('Fetching tips from:', tipsUrl);
          const tipsResponse = await fetch(tipsUrl);
          const tipsData = await tipsResponse.json();
          console.log('Tips data:', tipsData);

          // Extract allowance info from the most recent tip
          if (Array.isArray(tipsData) && tipsData.length > 0) {
            // Sort tips by timestamp to get the most recent
            const sortedTips = [...tipsData].sort((a, b) => 
              new Date(b.timestamp) - new Date(a.timestamp)
            );
            const latestTip = sortedTips[0];

            const allowanceInfo = {
              dailyAllowance: parseInt(latestTip.tip_allowance || 0),
              remainingAllowance: parseInt(latestTip.tip_allowance || 0) - parseInt(latestTip.rolling_daily_tip_amount || 0)
            };

            console.log('Calculated allowance info:', allowanceInfo);
            setAllowances(allowanceInfo);
          }

          setTips(tipsData);
        } catch (error) {
          console.error('Failed to fetch tips:', error);
          message.error('Failed to fetch tips data');
        }

      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error);
        message.error('Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [fid, connectedAddresses]);

  return (
    <Card
      title="Degen Report"
      style={{ marginBottom: 20, background: theme.secondary }}
      extra={
        <Dropdown overlay={dropdownMenu} placement="bottomRight" trigger={['click']}>
          <Button type="text" icon={<EllipsisOutlined />} />
        </Dropdown>
      }
    >
      {loading ? (
        <div style={{ textAlign: 'center', padding: '50px 0' }}>
          <Spin size="large" />
        </div>
      ) : error ? (
        <Alert
          message="Error Loading Degen Data"
          description={`An error occurred while fetching the Degen data: ${error.message}`}
          type="error"
          showIcon
        />
      ) : (
        <>
          <Row gutter={[24, 24]}>
            <Col span={8}>
              <Statistic
                title={<Text style={{ color: theme.text }}>Current Season Points</Text>}
                value={points}
                valueStyle={{ color: theme.highlight }}
              />
            </Col>
            <Col span={8}>
              <Statistic
                title={<Text style={{ color: theme.text }}>Daily Allowance</Text>}
                value={allowances.dailyAllowance}
                valueStyle={{ color: theme.highlight }}
              />
            </Col>
            <Col span={8}>
              <Statistic
                title={<Text style={{ color: theme.text }}>Remaining Allowance</Text>}
                value={allowances.remainingAllowance}
                valueStyle={{ color: theme.highlight }}
              />
            </Col>
          </Row>

          <Title level={4} style={{ color: theme.text, marginTop: 24 }}>Recent Tips</Title>
          <Table
            dataSource={tips}
            columns={tipColumns}
            rowKey={(record) => `${record.timestamp}-${record.cast_hash}`}
            pagination={{ pageSize: 10 }}
          />
        </>
      )}
    </Card>
  );
};

export default DegenReportCard;