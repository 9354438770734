import React, { useState } from 'react';
import { Card, Table, Typography, Spin, Row, Col, Image, Button, Space, message, Popover } from 'antd';
import { ThunderboltOutlined, QuestionCircleOutlined } from '@ant-design/icons';

const { Title, Text, Paragraph } = Typography;

const FarcardPopover = ({ fid }) => (
  <div style={{ display: 'flex', gap: '10px' }}>
    <Image
      src={`https://far.cards/api/card/${fid}/front`}
      alt="Farcard Front"
      style={{ width: '150px', height: 'auto' }}
    />
    <Image
      src={`https://far.cards/api/card/${fid}/back`}
      alt="Farcard Back"
      style={{ width: '150px', height: 'auto' }}
    />
  </div>
);

const FarcardRumble = ({ farcards }) => {
  const [loading, setLoading] = useState(false);
  const [card1, setCard1] = useState(null);
  const [card2, setCard2] = useState(null);
  const [winner, setWinner] = useState(null);

  const getRandomFarcard = () => {
    return farcards[Math.floor(Math.random() * farcards.length)];
  };

  const startBattle = () => {
    if (farcards.length < 2) {
      message.error('You need at least 2 Farcards to start a battle!');
      return;
    }

    setLoading(true);
    setWinner(null);
    setCard1(null);
    setCard2(null);

    setTimeout(() => {
      let farcard1, farcard2;

      do {
        farcard1 = getRandomFarcard();
        farcard2 = getRandomFarcard();
      } while (farcard1.fid === farcard2.fid);

      setCard1(farcard1);
      setCard2(farcard2);

      if (farcard1.engagement > farcard2.engagement) {
        setWinner(1);
      } else if (farcard2.engagement > farcard1.engagement) {
        setWinner(2);
      } else {
        setWinner(0); // Draw
      }

      setLoading(false);
    }, 5000); // 5 seconds delay
  };

  const CardContent = ({ card, isWinner }) => (
    <div style={{ color: isWinner ? '#ffffff' : 'inherit' }}>
      <Text strong style={{ color: 'inherit' }}>Power: {card.engagement}</Text>
      {isWinner && <ThunderboltOutlined style={{ fontSize: 24, color: '#ffffff', marginLeft: 8 }} />}
    </div>
  );

  return (
    <Card title={<Title level={4}>Farcard Rumble</Title>} style={{ marginTop: 20 }}>
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Paragraph>
          Imagine you have a bunch of trading cards, but these are special Farcards! Each card has a 
          secret power called "social capital" based on how popular their posts were last week. 
          When you click "Start Battle", we'll pick two random cards and see which one has the stronger power. 
          It's like a fun game of chance with your Farcards!
        </Paragraph>
        <Button onClick={startBattle} loading={loading} type="primary" icon={<ThunderboltOutlined />} size="large">
          Start Battle
        </Button>
        
        {loading && (
          <div style={{ textAlign: 'center', marginTop: 20 }}>
            <Spin size="large" />
            <Paragraph style={{ marginTop: 10 }}>The Farcards are powering up...</Paragraph>
          </div>
        )}
        
        {!loading && card1 && card2 && (
          <>
            <Row justify="center" align="middle" gutter={16}>
              <Col>
                <Card 
                  title={card1.fname}
                  style={{ 
                    width: 180, 
                    borderColor: winner === 1 ? '#D5663D' : undefined,
                    backgroundColor: winner === 1 ? '#D5663D' : undefined 
                  }}
                  headStyle={{ 
                    backgroundColor: winner === 1 ? '#D5663D' : undefined,
                    color: winner === 1 ? '#ffffff' : undefined 
                  }}
                  cover={<Image src={`https://far.cards/api/card/${card1.fid}/front`} alt="Farcard 1" style={{ width: '100%', height: 'auto' }} />}
                >
                  <CardContent card={card1} isWinner={winner === 1} />
                </Card>
              </Col>
              <Col>
                <ThunderboltOutlined style={{ fontSize: 48, color: '#D5663D' }} />
              </Col>
              <Col>
                <Card 
                  title={card2.fname}
                  style={{ 
                    width: 180, 
                    borderColor: winner === 2 ? '#D5663D' : undefined,
                    backgroundColor: winner === 2 ? '#D5663D' : undefined 
                  }}
                  headStyle={{ 
                    backgroundColor: winner === 2 ? '#D5663D' : undefined,
                    color: winner === 2 ? '#ffffff' : undefined 
                  }}
                  cover={<Image src={`https://far.cards/api/card/${card2.fid}/front`} alt="Farcard 2" style={{ width: '100%', height: 'auto' }} />}
                >
                  <CardContent card={card2} isWinner={winner === 2} />
                </Card>
              </Col>
            </Row>
            
            <Title level={4} style={{ textAlign: 'center', marginTop: 20 }}>
              {winner === 0 ? "It's a tie! Both cards are equally strong!" : `${winner === 1 ? card1.fname : card2.fname} wins with their super strength!`}
            </Title>
          </>
        )}
      </Space>
    </Card>
  );
};

const FarcardsCard = ({ farcards, farcardHolders, loading, loadingHolders, theme, profileHandle }) => {
  const [showRumble, setShowRumble] = useState(false);
  const userId = farcards?.user?.fid;

  const collectedColumns = [
    {
      title: 'PFP',
      dataIndex: 'pfp_url',
      key: 'pfp_url',
      render: (pfp_url, record) => (
        <Popover content={<FarcardPopover fid={record.fid} />} placement="right">
          <Image src={pfp_url} alt="Profile" width={50} />
        </Popover>
      ),
    },
    {
      title: 'FName',
      dataIndex: 'fname',
      key: 'fname',
    },
    {
      title: 'FID',
      dataIndex: 'fid',
      key: 'fid',
    },
    {
      title: 'Active Tier',
      dataIndex: 'fidActiveTier',
      key: 'fidActiveTier',
    },
    {
      title: 'Followers',
      dataIndex: 'followers',
      key: 'followers',
    },
    {
      title: 'Casts',
      dataIndex: 'casts',
      key: 'casts',
    },
    {
      title: 'Likes',
      dataIndex: 'gotLikes',
      key: 'gotLikes',
    },
    {
      title: 'Engagement',
      dataIndex: 'engagement',
      key: 'engagement',
    },
    {
      title: 'Recasts',
      dataIndex: 'gotRecasts',
      key: 'gotRecasts',
    },
    {
      title: 'Replies',
      dataIndex: 'gotReplies',
      key: 'gotReplies',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (price) => (typeof price === 'number' ? price.toFixed(6) : price),
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
    },
  ];

  const holdersColumns = [
    {
      title: 'User Address',
      dataIndex: 'userAddress',
      key: 'userAddress',
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
    },
  ];

  const collectedData = farcards?.collected || [];
  const holdersData = farcardHolders?.holders || [];

  const rumbleExplanation = (
    <div>
      <p>Farcard Rumble is a fun game where your Farcards battle each other!</p>
      <p>We look at how popular each card's posts were last week and give them a special power number.</p>
      <p>When you start a battle, we pick two cards randomly and see which one has the bigger power number.</p>
      <p>It's like a magical duel between your Farcards!</p>
    </div>
  );

  return (
    <Card
      title={`Farcards for @${profileHandle}`}
      style={{ marginBottom: 20, background: theme.secondary }}
      styles={{
        header: {
          color: theme.text,
          borderBottom: `1px solid ${theme.border}`,
        },
      }}
      extra={
        <Space>
          <Popover content={rumbleExplanation} title="What is Farcard Rumble?">
            <QuestionCircleOutlined style={{ fontSize: '16px', cursor: 'pointer' }} />
          </Popover>
          <Button 
            type="primary" 
            icon={<ThunderboltOutlined />} 
            onClick={() => setShowRumble(!showRumble)}
          >
            {showRumble ? 'Hide Farcard Rumble' : 'Show Farcard Rumble'}
          </Button>
        </Space>
      }
    >
      {loading || loadingHolders ? (
        <div style={{ textAlign: 'center', padding: '50px 0' }}>
          <Spin size="large" />
        </div>
      ) : userId ? (
        <>
          {showRumble && (
            <div style={{ marginBottom: 20 }}>
              <FarcardRumble farcards={collectedData} />
            </div>
          )}

          <Row justify="center" align="middle" style={{ marginBottom: '20px' }}>
            <Col xs={24} sm={20} md={16} lg={12} xl={10}>
              <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                <Image
                  src={`https://far.cards/api/card/${userId}/front`}
                  alt="Farcard Front"
                  style={{ width: '37.5%', height: 'auto' }}
                  preview={false}
                />
                <Image
                  src={`https://far.cards/api/card/${userId}/back`}
                  alt="Farcard Back"
                  style={{ width: '37.5%', height: 'auto' }}
                  preview={false}
                />
              </div>
            </Col>
          </Row>

          <Title level={4} style={{ color: theme.text, marginTop: '20px' }}>User Info</Title>
          <Text style={{ color: theme.text }}>
            FID: {farcards.user.fid}, FName: {farcards.user.fname}, Active Tier: {farcards.user.fidActiveTier}
          </Text>

          <Title level={4} style={{ color: theme.text, marginTop: '20px' }}>Collected Farcards</Title>
          <Table 
            dataSource={collectedData} 
            columns={collectedColumns} 
            rowKey="fid"
            pagination={false}
            style={{ background: theme.secondary, color: theme.text }}
          />

          <Title level={4} style={{ color: theme.text, marginTop: '20px' }}>Farcard Holders</Title>
          {farcardHolders ? (
            <>
              <Text style={{ color: theme.text }}>
                Total Balance: {farcardHolders.totalBalance}, Address Count: {farcardHolders.addressCount}
              </Text>
              <Table 
                dataSource={holdersData} 
                columns={holdersColumns} 
                rowKey="userAddress"
                pagination={false}
                style={{ background: theme.secondary, color: theme.text, marginTop: '10px' }}
              />
            </>
          ) : (
            <Text style={{ color: theme.text }}>No holder data available.</Text>
          )}
        </>
      ) : (
        <Text style={{ color: theme.text }}>No Farcards data available.</Text>
      )}
    </Card>
  );
};

export default FarcardsCard;