import React, { useState, useEffect, useCallback } from 'react';
import { Card, Table, Typography, Button, Dropdown, Menu, Statistic, Row, Col, Spin, message } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import axios from 'axios';
import { createPublicClient, http, formatEther } from 'viem';
import BigNumber from 'bignumber.js';

const { Text, Link } = Typography;

const RPC_URL = "https://rpc.ham.fun";

const messagePaidAbi = [
  {
    type: "event",
    name: "MessagePaid",
    inputs: [
      { name: "from", type: "uint256", indexed: true, internalType: "uint256" },
      { name: "to", type: "uint256", indexed: true, internalType: "uint256" },
      { name: "parentId", type: "string", indexed: true, internalType: "string" },
      { name: "amount", type: "uint256", indexed: false, internalType: "uint256" },
      { name: "messageId", type: "string", indexed: false, internalType: "string" },
    ],
    anonymous: false,
  },
];

const HamReportCard = ({ fid, theme, profileHandle }) => {
  const [hamData, setHamData] = useState(null);
  const [tokenBalances, setTokenBalances] = useState([]);
  const [floatyBalances, setFloatyBalances] = useState([]);
  const [latestTips, setLatestTips] = useState([]);
  const [casterTokens, setCasterTokens] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingCasterTokens, setLoadingCasterTokens] = useState(true);
  const [connectedAddresses, setConnectedAddresses] = useState([]);
  const [leaderboard, setLeaderboard] = useState([]);
  const [loadingLeaderboard, setLoadingLeaderboard] = useState(true);

  const convertToNumber = (value) => {
    if (typeof value === 'string') {
      return parseFloat(value) / 1e18;
    }
    return value;
  };

  const fetchConnectedAddresses = useCallback(async (userId) => {
    try {
      const query = `
        query MyQuery {
          Socials(input: {filter: {userId: {_eq: "${userId}"}}, blockchain: ethereum}) {
            Social {
              connectedAddresses {
                address
              }
            }
          }
        }
      `;
      const response = await axios.post(
        'https://api.airstack.xyz/gql',
        { query },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_AIRSTACK_API_KEY}`
          }
        }
      );
      const addresses = response.data.data.Socials.Social[0].connectedAddresses.map(item => item.address);
      setConnectedAddresses(addresses);
      console.log('Connected Addresses:', addresses);
      return addresses;
    } catch (error) {
      console.error('Error fetching connected addresses:', error);
      return [];
    }
  }, []);

  const fetchCasterTokens = useCallback(async (addresses) => {
    setLoadingCasterTokens(true);
    try {
      const allTokens = await Promise.all(
        addresses.map(async (address) => {
          try {
            const response = await axios.get(`https://explorer.ham.fun/api/v2/addresses/${address}/tokens?type=ERC-20%2CERC-721%2CERC-1155`);
            return response.data.items
              .filter(item => 
                item.token.symbol !== 'HAM' && 
                item.token.symbol !== 'TN100x'
              )
              .map(item => ({
                symbol: item.token.symbol,
                name: item.token.name,
                balance: convertToNumber(item.value),
                decimals: parseInt(item.token.decimals),
                address: address,
              }));
          } catch (error) {
            console.error(`Error fetching tokens for address ${address}:`, error);
            return [];
          }
        })
      );

      const combinedTokens = allTokens.flat();
      setCasterTokens(combinedTokens);
      console.log('Processed Caster Tokens:', combinedTokens);
    } catch (error) {
      console.error('Error fetching Caster Tokens:', error);
      message.error('Failed to fetch Caster Tokens. Please try again later.');
    } finally {
      setLoadingCasterTokens(false);
    }
  }, []);

  const fetchFloatyBalances = useCallback(async (addresses) => {
    try {
      const combinedBalances = [];
      for (const address of addresses) {
        const response = await axios.get(`https://farcaster.dep.dev/floaties/balance/${address}`);
        console.log(`Floaty Response for ${address}:`, response.data);
        
        if (response.data.balances) {
          response.data.balances.forEach(floaty => {
            const existingToken = combinedBalances.find(item => item.symbol === floaty.emoji);
            if (existingToken) {
              existingToken.balance += floaty.total;
            } else {
              combinedBalances.push({
                symbol: floaty.emoji,
                balance: floaty.total,
                floatyHash: floaty.floatyHash,
              });
            }
          });
        }
      }

      return combinedBalances;
    } catch (error) {
      console.error('Error fetching Floaty Balances:', error);
      return [];
    }
  }, []);

  const fetchLeaderboard = useCallback(async () => {
    setLoadingLeaderboard(true);
    try {
      const response = await axios.get('https://farcaster.dep.dev/ham/ham-scores?page=1');
      console.log('Leaderboard response:', response.data);

      if (response.data && Array.isArray(response.data.data)) {
        setLeaderboard(response.data.data.slice(0, 10)); // Get top 10 users
      } else {
        console.error('Unexpected leaderboard data structure:', response.data);
        setLeaderboard([]); // Set to empty array if data structure is unexpected
      }
    } catch (error) {
      console.error('Error fetching leaderboard:', error);
      message.error('Failed to fetch leaderboard. Please try again later.');
      setLeaderboard([]);
    } finally {
      setLoadingLeaderboard(false);
    }
  }, []);

  useEffect(() => {
    const fetchHamData = async () => {
      setLoading(true);
      try {
        console.log('Fetching HAM data for FID:', fid);
        const hamResponse = await axios.get(`https://farcaster.dep.dev/ham/user/${fid}`);
        console.log('HAM Response:', hamResponse.data);

        const dailyAllocation = convertToNumber(hamResponse.data.todaysAllocation);
        const percentTipped = hamResponse.data.percentTipped;
        const remainingAllocationPercent = (1 - percentTipped) * 100;

        const convertedHamData = {
          dailyAllocation,
          hamBalance: convertToNumber(hamResponse.data.balance.ham),
          hamScore: hamResponse.data.hamScore,
          rank: hamResponse.data.rank,
          percentTipped: percentTipped * 100,
          remainingAllocation: dailyAllocation * (1 - percentTipped),
          remainingAllocationPercent,
        };

        setHamData(convertedHamData);
        console.log('Converted HAM Data:', convertedHamData);

        // Process token balances (excluding score-related tokens)
        const tokens = Object.entries(hamResponse.data.rawScores)
          .filter(([key]) => !key.includes('Score'))
          .map(([symbol, balance]) => ({
            symbol,
            balance: convertToNumber(balance),
          }));
        setTokenBalances(tokens);
        console.log('Token Balances:', tokens);

        // Fetch connected addresses
        const addresses = await fetchConnectedAddresses(fid);
        
        // Fetch Floaty Balances for all connected addresses
        if (addresses.length > 0) {
          const combinedFloatyBalances = await fetchFloatyBalances(addresses);
          setFloatyBalances(combinedFloatyBalances);
          console.log('Combined Floaty Balances:', combinedFloatyBalances);
        }

        // Fetch Caster Tokens for all connected addresses
        if (addresses.length > 0) {
          fetchCasterTokens(addresses);
        }

        // Fetch Leaderboard
        fetchLeaderboard();

      } catch (error) {
        console.error('Error fetching TN100X/HAM data:', error);
        setHamData(null);
        setTokenBalances([]);
        setFloatyBalances([]);
        message.error('Failed to fetch TN100X/HAM data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    if (fid) {
      fetchHamData();
    }
  }, [fid, fetchConnectedAddresses, fetchFloatyBalances, fetchCasterTokens, fetchLeaderboard]);

  useEffect(() => {
    const client = createPublicClient({
      transport: http(RPC_URL),
    });

    const unwatch = client.watchContractEvent({
      address: "0x7a6B7Ad9259c57fD599E1162c6375B7eA63864e4",
      abi: messagePaidAbi,
      onLogs: (logs) => {
        console.log('Raw logs:', logs);

        const tips = logs.map((log) => {
          const {
            from: fromFid,
            to: toFid,
            amount,
            messageId: castHash,
          } = log.args;

          const tipInfo = `${fromFid?.toString()} sent ${toFid?.toString()} ${formatEther(amount)} $HAM`;
          console.log(tipInfo);

          return {
            fromFid: fromFid?.toString(),
            toFid: toFid?.toString(),
            amount: formatEther(amount),
            castHash,
          };
        });

        setLatestTips(tips);
      },
    });

    return () => unwatch();
  }, []);

  const menu = (
    <Menu>
      <Menu.Item key="warpcastProfile">
        <a href={`https://warpcast.com/${profileHandle}`} target="_blank" rel="noopener noreferrer">
          Open Warpcast Profile
        </a>
      </Menu.Item>
      <Menu.Item key="castFrame">
        <a href={`https://warpcast.com/~/compose?text=${encodeURIComponent('Check out my TN100X/HAM report: [Your TN100X/HAM Report Frame URL]')}`} target="_blank" rel="noopener noreferrer">
          Cast TN100X/HAM Report Frame
        </a>
      </Menu.Item>
    </Menu>
  );

  const tokenColumns = [
    {
      title: 'Token',
      dataIndex: 'symbol',
      key: 'symbol',
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      render: (balance) => {
        if (balance === undefined || balance === null) return 'N/A';
        return typeof balance === 'number' ? balance.toFixed(6) : balance.toString();
      },
    },
  ];

  const floatyColumns = [
    {
      title: 'Floaty',
      dataIndex: 'symbol',
      key: 'symbol',
      render: (symbol) => <span style={{ fontSize: '20px' }}>{symbol || 'N/A'}</span>,
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      render: (balance) => {
        if (balance === undefined || balance === null) return 'N/A';
        return balance.toLocaleString();
      },
    },
    {
      title: 'Floaty Hash',
      dataIndex: 'floatyHash',
      key: 'floatyHash',
      render: (hash) => {
        if (!hash) return 'N/A';
        return `${hash.substring(0, 6)}...${hash.substring(hash.length - 4)}`;
      },
    },
  ];

  const casterTokenColumns = [
    {
      title: 'Token',
      dataIndex: 'symbol',
      key: 'symbol',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      render: (balance) => {
        if (balance === undefined || balance === null) return 'N/A';
        return typeof balance === 'number' ? balance.toFixed(6) : new BigNumber(balance).toFixed(6);
      },
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      render: (address) => `${address.substring(0, 6)}...${address.substring(address.length - 4)}`,
    },
  ];

  const leaderboardColumns = [
    {
      title: 'Rank',
      dataIndex: 'rank',
      key: 'rank',
    },
    {
      title: 'FID',
      dataIndex: 'fid',
      key: 'fid',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      render: (username) => username || 'N/A',
    },
    {
      title: 'HAM Score',
      dataIndex: 'hamScore',
      key: 'hamScore',
      render: (score) => (score !== undefined ? score.toFixed(2) : 'N/A'),
    },
    {
      title: 'Profile',
      dataIndex: 'fid',
      key: 'profile',
      render: (fid) => (
        <Link href={`https://warpcast.com/~/profile/${fid}`} target="_blank">
          View Profile
        </Link>
      ),
    },
  ];

  const tipColumns = [
    {
      title: 'From',
      dataIndex: 'fromFid',
      key: 'fromFid',
    },
    {
      title: 'To',
      dataIndex: 'toFid',
      key: 'toFid',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => `${amount} 🍖`,
    },
    {
      title: 'Cast Hash',
      dataIndex: 'castHash',
      key: 'castHash',
      render: (hash) => hash ? `${hash.substring(0, 10)}...` : 'N/A',
    },
  ];

  const StatisticWrapper = ({ title, value, suffix, precision }) => (
    <Statistic
      title={<Text style={{ color: theme.text }}>{title}</Text>}
      value={value}
      valueStyle={{ color: theme.highlight }}
      suffix={suffix}
      precision={precision}
    />
  );

  return (
    <Card
      title="TN100X/HAM Report"
      style={{ marginBottom: 20, background: theme.secondary }}
      extra={
        <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
          <Button type="text" icon={<EllipsisOutlined />} />
        </Dropdown>
      }
    >
      {loading ? (
        <Spin size="large" />
      ) : (
        <>
          <Row gutter={[24, 24]}>
            <Col span={6}>
              <StatisticWrapper
                title="Daily Allocation"
                value={hamData?.dailyAllocation}
                suffix="🍖"
                precision={4}
              />
            </Col>
            <Col span={6}>
              <StatisticWrapper
                title="Remaining Allocation"
                value={hamData?.remainingAllocation}
                suffix="🍖"
                precision={4}
              />
            </Col>
            <Col span={6}>
              <StatisticWrapper
                title="Percent Remaining"
                value={hamData?.remainingAllocationPercent}
                suffix="%"
                precision={2}
              />
            </Col>
            <Col span={6}>
              <StatisticWrapper
                title="Percent Tipped"
                value={hamData?.percentTipped}
                suffix="%"
                precision={2}
              />
            </Col>
          </Row>
          <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
            <Col span={6}>
              <StatisticWrapper
                title="HAM Balance"
                value={hamData?.hamBalance}
                suffix="🍖"
                precision={4}
              />
            </Col>
            <Col span={6}>
              <StatisticWrapper
                title="HAM Score"
                value={hamData?.hamScore}
                precision={4}
              />
            </Col>
            <Col span={6}>
              <StatisticWrapper
                title="Rank"
                value={hamData?.rank}
              />
            </Col>
            <Col span={6}></Col> {/* Empty column for alignment */}
          </Row>

          <Table
            title={() => <Text strong style={{ color: theme.text }}>Connected Addresses</Text>}
            dataSource={connectedAddresses.map(address => ({ address }))}
            columns={[
              {
                title: 'Address',
                dataIndex: 'address',
                key: 'address',
                render: (address) => `${address.substring(0, 6)}...${address.substring(address.length - 4)}`,
              }
            ]}
            rowKey="address"
            pagination={false}
            style={{ marginTop: 20 }}
          />

          <Table
            title={() => <Text strong style={{ color: theme.text }}>Token Balances</Text>}
            dataSource={tokenBalances}
            columns={tokenColumns}
            rowKey="symbol"
            pagination={false}
            style={{ marginTop: 20 }}
          />

          <Table
            title={() => <Text strong style={{ color: theme.text }}>Floaty Balances</Text>}
            dataSource={floatyBalances}
            columns={floatyColumns}
            rowKey={(record) => record.floatyHash || record.symbol}
            pagination={false}
            style={{ marginTop: 20 }}
          />

          {casterTokens.length > 0 && (
            <Table
              title={() => <Text strong style={{ color: theme.text }}>Caster Tokens</Text>}
              dataSource={casterTokens}
              columns={casterTokenColumns}
              rowKey={(record) => `${record.symbol}-${record.address}`}
              pagination={false}
              style={{ marginTop: 20, background: theme.secondary }}
              loading={loadingCasterTokens}
            />
          )}

          <Table
            title={() => <Text strong style={{ color: theme.text }}>HAM Leaderboard (Top 10)</Text>}
            dataSource={leaderboard}
            columns={leaderboardColumns}
            rowKey="fid"
            pagination={false}
            loading={loadingLeaderboard}
            style={{ marginTop: 20 }}
          />

          <Table
            title={() => <Text strong style={{ color: theme.text }}>Latest Tips</Text>}
            dataSource={latestTips}
            columns={tipColumns}
            rowKey={(record, index) => index}
            pagination={false}
            style={{ marginTop: 20 }}
          />
        </>
      )}
    </Card>
  );
};

export default HamReportCard;