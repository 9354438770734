import React from 'react';
import FarcasterLookup from './components/FarcasterLookup';
import { ConfigProvider, theme } from 'antd';

function App() {
  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: '#D5663D',
        },
      }}
    >
      <FarcasterLookup />
    </ConfigProvider>
  );
}

export default App;