import React from 'react';
import { Card, Row, Col, Statistic, Typography, Button, Dropdown, Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';

const { Text } = Typography;

const ReplykeMetricsCard = ({ replykeMetrics, moxiePriceUSD, theme, profileHandle }) => {
  const menu = (
    <Menu>
      <Menu.Item key="warpcastProfile">
        <a href={`https://warpcast.com/${profileHandle}`} target="_blank" rel="noopener noreferrer">
          Open Warpcast Profile
        </a>
      </Menu.Item>
      <Menu.Item key="castFrame">
        <a href={`https://warpcast.com/~/compose?text=${encodeURIComponent('Check out my Replyke Metrics: yourmoxiestats.basedcoder.link')}`} target="_blank" rel="noopener noreferrer">
          @abss Replyke Metrics Frame 1
        </a>
      </Menu.Item>
      <Menu.Item key="castFrame1">
        <a href={`https://warpcast.com/~/compose?text=${encodeURIComponent('Check out my Replyke Metrics: replykepowercheck.basedcoder.link')}`} target="_blank" rel="noopener noreferrer">
          @abss Replyke Metrics Frame 2
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <Card
      title="Replyke Metrics"
      style={{ marginBottom: 20, background: theme.secondary }}
      extra={
        <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
          <Button type="text" icon={<EllipsisOutlined />} />
        </Dropdown>
      }
    >
      <Row gutter={16}>
        <Col span={6}>
          <Statistic
            title={<Text style={{ color: theme.text }}>FarBoost</Text>}
            value={replykeMetrics.farBoost.toFixed(2)}
            valueStyle={{ color: theme.highlight }}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title={<Text style={{ color: theme.text }}>Like Reward</Text>}
            value={replykeMetrics.likeReward.toFixed(2)}
            precision={2}
            valueStyle={{ color: theme.highlight }}
            prefix="Ⓜ️"
            suffix={
              moxiePriceUSD ? (
                <Text style={{ fontSize: '0.8em', color: theme.textSecondary }}>
                  (≈ ${(replykeMetrics.likeReward * moxiePriceUSD).toFixed(2)} USD)
                </Text>
              ) : ''
            }
          />
        </Col>
        <Col span={6}>
          <Statistic
            title={<Text style={{ color: theme.text }}>Reply Reward</Text>}
            value={replykeMetrics.replyReward.toFixed(2)}
            precision={2}
            valueStyle={{ color: theme.highlight }}
            prefix="Ⓜ️"
            suffix={
              moxiePriceUSD ? (
                <Text style={{ fontSize: '0.8em', color: theme.textSecondary }}>
                  (≈ ${(replykeMetrics.replyReward * moxiePriceUSD).toFixed(2)} USD)
                </Text>
              ) : ''
            }
          />
        </Col>
        <Col span={6}>
          <Statistic
            title={<Text style={{ color: theme.text }}>Recast Reward</Text>}
            value={replykeMetrics.recastReward.toFixed(2)}
            precision={2}
            valueStyle={{ color: theme.highlight }}
            prefix="Ⓜ️"
            suffix={
              moxiePriceUSD ? (
                <Text style={{ fontSize: '0.8em', color: theme.textSecondary }}>
                  (≈ ${(replykeMetrics.recastReward * moxiePriceUSD).toFixed(2)} USD)
                </Text>
              ) : ''
            }
          />
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: '20px' }}>
        <Col span={24}>
          <Statistic
            title={<Text style={{ color: theme.text }}>Total Replyke Reward</Text>}
            value={replykeMetrics.totalReward.toFixed(2)}
            precision={2}
            valueStyle={{ color: theme.highlight, fontSize: '24px' }}
            prefix="Ⓜ️"
            suffix={
              moxiePriceUSD ? (
                <Text style={{ fontSize: '0.8em', color: theme.textSecondary }}>
                  (≈ ${(replykeMetrics.totalReward * moxiePriceUSD).toFixed(2)} USD)
                </Text>
              ) : ''
            }
          />
        </Col>
      </Row>
    </Card>
  );
};

export default ReplykeMetricsCard;
