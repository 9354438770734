import React, { useState, useEffect, useMemo } from 'react';
import { Card, Table, Input, Typography, Button, Dropdown, Menu, Avatar } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Search } = Input;
const { Text, Link } = Typography;

const FollowersCard = ({ followers, loading, theme, profileHandle }) => {
  const [followerSearch, setFollowerSearch] = useState('');
  const [followersWithProfiles, setFollowersWithProfiles] = useState([]);

  useEffect(() => {
    const fetchFollowerProfiles = async () => {
      const updatedFollowers = await Promise.all(followers.map(async (follower) => {
        try {
          const query = `
            query GetProfileInfo($fid: String!) {
              Socials(input: {filter: {userId: {_eq: $fid}}, blockchain: ethereum}) {
                Social {
                  profileName
                  profileImage
                }
              }
            }
          `;
          const variables = { fid: follower.followerProfileId };
          const response = await axios.post(
            'https://api.airstack.xyz/gql',
            { query, variables },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${process.env.REACT_APP_AIRSTACK_API_KEY}`,
              },
            }
          );
          const profileData = response.data.data?.Socials?.Social[0];
          return { ...follower, profileName: profileData?.profileName, profileImage: profileData?.profileImage };
        } catch (error) {
          console.error('Error fetching follower profile:', error);
          return follower;
        }
      }));
      setFollowersWithProfiles(updatedFollowers);
    };

    if (followers.length > 0) {
      fetchFollowerProfiles();
    }
  }, [followers]);

  const menu = (
    <Menu>
      <Menu.Item key="warpcastProfile">
        <a href={`https://warpcast.com/${profileHandle}`} target="_blank" rel="noopener noreferrer">
          Open Warpcast Profile
        </a>
      </Menu.Item>
      <Menu.Item key="castFrame">
        <a href={`https://warpcast.com/~/compose?text=${encodeURIComponent('Check out my followers: [Your Followers Frame URL]')}`} target="_blank" rel="noopener noreferrer">
          Cast Followers Frame
        </a>
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: 'Follower',
      dataIndex: 'profileName',
      key: 'profileName',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar src={record.profileImage} style={{ marginRight: '8px', backgroundColor: theme.highlight }}>
            {!record.profileImage && (text ? text[0].toUpperCase() : 'F')}
          </Avatar>
          <Link href={`https://warpcast.com/${text || record.followerProfileId}`} target="_blank" style={{ color: theme.highlight }}>
            {text || record.followerProfileId}
          </Link>
        </div>
      ),
    },
    {
      title: 'Follower Since',
      dataIndex: 'followerSince',
      key: 'followerSince',
      render: (text) => new Date(text).toLocaleString(),
      sorter: (a, b) => new Date(a.followerSince) - new Date(b.followerSince),
    },
    {
      title: 'Identity',
      dataIndex: 'identity',
      key: 'identity',
    },
    {
      title: 'Addresses',
      dataIndex: 'addresses',
      key: 'addresses',
      render: (addresses) => addresses.join(', '),
      ellipsis: true,
    },
  ];

  const filteredFollowers = useMemo(() => {
    return followersWithProfiles.filter(
      (follower) =>
        follower.profileName?.toLowerCase().includes(followerSearch.toLowerCase()) ||
        follower.followerProfileId.toLowerCase().includes(followerSearch.toLowerCase()) ||
        follower.addresses.some((address) => address.toLowerCase().includes(followerSearch.toLowerCase()))
    );
  }, [followersWithProfiles, followerSearch]);

  return (
    <Card
      title="Followers"
      style={{ marginBottom: 20, background: theme.secondary }}
      extra={
        <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
          <Button type="text" icon={<EllipsisOutlined />} />
        </Dropdown>
      }
    >
      <Search
        placeholder="Search Followers by Name, FID or Address"
        onSearch={(value) => setFollowerSearch(value.trim())}
        style={{ marginBottom: 16, maxWidth: 400 }}
        allowClear
      />
      <Table
        columns={columns}
        dataSource={filteredFollowers}
        loading={loading}
        pagination={{ pageSize: 10 }}
        scroll={{ x: 'max-content' }}
        rowKey="key"
      />
    </Card>
  );
};

export default FollowersCard;