import React from 'react';
import { Card, Row, Col, Avatar, Typography, Statistic, Button, Dropdown, Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';

const { Title, Text, Paragraph } = Typography;

const ProfileCard = ({ profileData, theme }) => {
  const menu = (
    <Menu>
      <Menu.Item key="warpcastProfile">
        <a href={`https://warpcast.com/${profileData.profileHandle}`} target="_blank" rel="noopener noreferrer">
          Open Warpcast Profile
        </a>
      </Menu.Item>
      <Menu.Item key="castFrame">
        <a href={`https://warpcast.com/~/compose?text=${encodeURIComponent('Check out my Farcaster profile: [Your Profile Frame URL]')}`} target="_blank" rel="noopener noreferrer">
          Cast Profile Frame
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <Card
      title="Profile Data"
      style={{ marginBottom: 20, background: theme.secondary }}
      extra={
        <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
          <Button type="text" icon={<EllipsisOutlined />} />
        </Dropdown>
      }
    >
      <Row gutter={16} align="middle">
        <Col xs={24} sm={8} md={6} lg={6} xl={4}>
          <Avatar size={128} src={profileData.profileImage} />
        </Col>
        <Col xs={24} sm={16} md={18} lg={18} xl={20}>
          <Title level={4} style={{ color: theme.text }}>
            {profileData.profileName || profileData.profileHandle}
          </Title>
          <Text style={{ color: theme.textSecondary }}>
            @{profileData.profileHandle || profileData.profileName}
          </Text>
          <Paragraph style={{ color: theme.text }}>
            {profileData.profileBio}
          </Paragraph>
          <Row gutter={16}>
            <Col span={8}>
              <Statistic
                title={<Text style={{ color: theme.text }}>Followers</Text>}
                value={profileData.followerCount}
                valueStyle={{ color: theme.highlight }}
              />
            </Col>
            <Col span={8}>
              <Statistic
                title={<Text style={{ color: theme.text }}>Following</Text>}
                value={profileData.followingCount}
                valueStyle={{ color: theme.highlight }}
              />
            </Col>
            <Col span={8}>
              <Statistic
                title={<Text style={{ color: theme.text }}>FarBoost</Text>}
                value={profileData.farcasterScore?.farBoost?.toFixed(2)}
                valueStyle={{ color: theme.highlight }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default ProfileCard;