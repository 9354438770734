import React, { useMemo } from 'react';
import { Card, Table, Typography, Button, Dropdown, Menu, Row, Col, Statistic } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import BigNumber from 'bignumber.js';

const { Text, Link } = Typography;

const PortfolioCard = ({ portfolio, loading, theme, profileHandle }) => {
  console.log('PortfolioCard: Received portfolio data', portfolio);

  const formatBigNumber = (value, shouldDivide = false, displayDecimals = 4) => {
    if (!value) return '0';
    const bigNum = new BigNumber(value);
    let formatted;
    if (shouldDivide) {
      formatted = bigNum.dividedBy(new BigNumber(10).pow(18)).toFixed(displayDecimals);
    } else {
      formatted = bigNum.toFixed(displayDecimals);
    }
    console.log(`formatBigNumber: Input ${value}, Output ${formatted}, ShouldDivide: ${shouldDivide}`);
    return formatted;
  };

  const columns = [
    {
      title: 'Fan Token',
      dataIndex: 'fanTokenName',
      key: 'fanTokenName',
      render: (text, record) => (
        <Link href={`https://basescan.org/token/${record.fanTokenAddress}`} target="_blank">
          {text}
        </Link>
      ),
    },
    {
      title: 'Current Price',
      dataIndex: 'currentPrice',
      key: 'currentPrice',
      render: (text) => {
        console.log('Current Price raw value:', text);
        return `Ⓜ️${formatBigNumber(text)}`;
      },
      sorter: (a, b) => new BigNumber(a.currentPrice || 0).minus(new BigNumber(b.currentPrice || 0)).toNumber(),
    },
    {
      title: 'Locked Amount',
      dataIndex: 'totalLockedAmount',
      key: 'totalLockedAmount',
      render: (text) => {
        console.log('Locked Amount raw value:', text);
        return formatBigNumber(text);
      },
      sorter: (a, b) => new BigNumber(a.totalLockedAmount || 0).minus(new BigNumber(b.totalLockedAmount || 0)).toNumber(),
    },
    {
      title: 'Unlocked Amount',
      dataIndex: 'totalUnlockedAmount',
      key: 'totalUnlockedAmount',
      render: (text) => {
        console.log('Unlocked Amount raw value:', text);
        return formatBigNumber(text);
      },
      sorter: (a, b) => new BigNumber(a.totalUnlockedAmount || 0).minus(new BigNumber(b.totalUnlockedAmount || 0)).toNumber(),
    },
    {
      title: 'Locked TVL',
      dataIndex: 'lockedTvl',
      key: 'lockedTvl',
      render: (text) => {
        console.log('Locked TVL raw value:', text);
        return `Ⓜ️${formatBigNumber(text)}`;
      },
      sorter: (a, b) => new BigNumber(a.lockedTvl || 0).minus(new BigNumber(b.lockedTvl || 0)).toNumber(),
    },
    {
      title: 'Unlocked TVL',
      dataIndex: 'unlockedTvl',
      key: 'unlockedTvl',
      render: (text) => {
        console.log('Unlocked TVL raw value:', text);
        return `Ⓜ️${formatBigNumber(text)}`;
      },
      sorter: (a, b) => new BigNumber(a.unlockedTvl || 0).minus(new BigNumber(b.unlockedTvl || 0)).toNumber(),
    },
  ];

  const portfolioSummary = useMemo(() => {
    console.log('Calculating portfolio summary');
    const summary = portfolio.reduce((acc, token) => {
      console.log('Processing token', token);
      acc.totalLockedTVL = acc.totalLockedTVL.plus(new BigNumber(token.lockedTvl || 0));
      acc.totalUnlockedTVL = acc.totalUnlockedTVL.plus(new BigNumber(token.unlockedTvl || 0));
      acc.totalLockedAmount = acc.totalLockedAmount.plus(new BigNumber(token.totalLockedAmount || 0));
      acc.totalUnlockedAmount = acc.totalUnlockedAmount.plus(new BigNumber(token.totalUnlockedAmount || 0));
      return acc;
    }, {
      totalLockedTVL: new BigNumber(0),
      totalUnlockedTVL: new BigNumber(0),
      totalLockedAmount: new BigNumber(0),
      totalUnlockedAmount: new BigNumber(0),
    });

    const totalTVL = summary.totalLockedTVL.plus(summary.totalUnlockedTVL);
    const lockedTVLPercentage = totalTVL.isZero() 
      ? 0 
      : summary.totalLockedTVL.dividedBy(totalTVL).multipliedBy(100).toNumber();

    console.log('Portfolio summary calculated', {
      totalLockedTVL: summary.totalLockedTVL.toString(),
      totalUnlockedTVL: summary.totalUnlockedTVL.toString(),
      totalLockedAmount: summary.totalLockedAmount.toString(),
      totalUnlockedAmount: summary.totalUnlockedAmount.toString(),
      totalTVL: totalTVL.toString(),
      lockedTVLPercentage,
    });

    return {
      ...summary,
      totalTVL,
      lockedTVLPercentage,
    };
  }, [portfolio]);

  console.log('PortfolioCard: Rendering with summary', portfolioSummary);

  const menu = (
    <Menu>
      <Menu.Item key="warpcastProfile">
        <a href={`https://warpcast.com/${profileHandle}`} target="_blank" rel="noopener noreferrer">
          Open Warpcast Profile
        </a>
      </Menu.Item>
      <Menu.Item key="castFrame">
        <a href={`https://warpcast.com/~/compose?text=${encodeURIComponent('Check out my Fan Token Portfolio: [Your Portfolio Frame URL]')}`} target="_blank" rel="noopener noreferrer">
          Cast Portfolio Frame
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <Card
      title="Fan Token Portfolio"
      style={{ marginBottom: 20, background: theme.secondary }}
      extra={
        <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
          <Button type="text" icon={<EllipsisOutlined />} />
        </Dropdown>
      }
    >
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col span={6}>
          <Statistic
            title="Total TVL"
            value={formatBigNumber(portfolioSummary.totalTVL)}
            prefix="Ⓜ️"
            valueStyle={{ color: theme.highlight }}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Locked TVL"
            value={formatBigNumber(portfolioSummary.totalLockedTVL)}
            prefix="Ⓜ️"
            valueStyle={{ color: theme.highlight }}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Unlocked TVL"
            value={formatBigNumber(portfolioSummary.totalUnlockedTVL)}
            prefix="Ⓜ️"
            valueStyle={{ color: theme.highlight }}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="% of TVL Locked"
            value={portfolioSummary.lockedTVLPercentage.toFixed(2)}
            suffix="%"
            valueStyle={{ color: theme.highlight }}
          />
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col span={12}>
          <Statistic
            title="Total Locked Amount"
            value={formatBigNumber(portfolioSummary.totalLockedAmount)}
            prefix="Ⓜ️"
            valueStyle={{ color: theme.highlight }}
          />
        </Col>
        <Col span={12}>
          <Statistic
            title="Total Unlocked Amount"
            value={formatBigNumber(portfolioSummary.totalUnlockedAmount)}
            prefix="Ⓜ️"
            valueStyle={{ color: theme.highlight }}
          />
        </Col>
      </Row>
      <Table
        dataSource={portfolio}
        columns={columns}
        loading={loading}
        pagination={{ pageSize: 10 }}
        scroll={{ x: 'max-content' }}
        rowKey="fanTokenAddress"
      />
    </Card>
  );
};

export default PortfolioCard;