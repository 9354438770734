export const themeColors = {
    dark: {
      primary: '#121212',
      secondary: '#333333',
      text: '#ffffff',
      textSecondary: '#b3b3b3',
      highlight: '#D5663D',
      border: '#444444',
      tableBackground: '#121212',
      tableText: '#ffffff',
      tableRowHover: '#1e1e1e',
    },
    light: {
      primary: '#ffffff',
      secondary: '#f0f2f5',
      text: '#000000',
      textSecondary: '#666666',
      highlight: '#D5663D',
      border: '#d9d9d9',
      tableBackground: '#ffffff',
      tableText: '#000000',
      tableRowHover: '#fafafa',
    },
  };