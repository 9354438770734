import React from 'react';
import { Card, Table, Typography, Button, Dropdown, Menu, Avatar } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import BigNumber from 'bignumber.js';

const { Text, Link } = Typography;

const UpcomingAuctionsCard = ({ upcomingAuctions, loading, theme, profileHandle }) => {
  const menu = (
    <Menu>
      <Menu.Item key="warpcastProfile">
        <a href={`https://warpcast.com/${profileHandle}`} target="_blank" rel="noopener noreferrer">
          Open Warpcast Profile
        </a>
      </Menu.Item>
      <Menu.Item key="castFrame">
        <a href={`https://warpcast.com/~/compose?text=${encodeURIComponent('Check out upcoming auctions: [Your Upcoming Auctions Frame URL]')}`} target="_blank" rel="noopener noreferrer">
          Cast Upcoming Auctions Frame
        </a>
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: 'Entity',
      dataIndex: 'entityName',
      key: 'entityName',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar src={record.profileImage} style={{ marginRight: 8 }}>
            {text.charAt(0).toUpperCase()}
          </Avatar>
          <Link href={`https://airstack.xyz/users/fc_fname%3A${encodeURIComponent(text.replace('/', ''))}`} target="_blank">
            {text}
          </Link>
        </div>
      ),
    },
    {
      title: 'Start Time',
      dataIndex: 'estimatedStartTimestamp',
      key: 'estimatedStartTimestamp',
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: 'End Time',
      dataIndex: 'estimatedEndTimestamp',
      key: 'estimatedEndTimestamp',
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: 'Auction Supply',
      dataIndex: 'auctionSupply',
      key: 'auctionSupply',
      render: (text) => new BigNumber(text).dividedBy(1e18).toFormat(2),
    },
    {
      title: 'Lifetime Moxie Earnings',
      dataIndex: 'lifetimeEarnings',
      key: 'lifetimeEarnings',
      render: (text) => `Ⓜ️${new BigNumber(text).toFormat(2)}`,
    },
    {
      title: 'Reward Distribution',
      dataIndex: 'rewardDistributionPercentage',
      key: 'rewardDistribution',
      render: (distribution) => (
        <div>
          <p>Channel Fans: {distribution.channelFans}%</p>
          <p>Creator: {distribution.creator}%</p>
          <p>Creator Fans: {distribution.creatorFans}%</p>
          <p>Network: {distribution.network}%</p>
        </div>
      ),
    },
  ];

  return (
    <Card
      title="Upcoming Auctions"
      style={{ marginBottom: 20, background: theme.secondary }}
      extra={
        <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
          <Button type="text" icon={<EllipsisOutlined />} />
        </Dropdown>
      }
    >
      <Table
        dataSource={upcomingAuctions}
        columns={columns}
        rowKey="entityName"
        loading={loading}
        pagination={{ pageSize: 10 }}
        scroll={{ x: 'max-content' }}
      />
    </Card>
  );
};

export default UpcomingAuctionsCard;