import React from 'react';
import { Card, Row, Col, Statistic, Typography, Button, Dropdown, Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';

const { Text } = Typography;

const MoxieClaimsCard = ({ moxieClaims, moxiePriceUSD, theme, profileData }) => {
  const menu = (
    <Menu>
      <Menu.Item key="warpcastProfile">
        <a href={`https://warpcast.com/${profileData.profileHandle}`} target="_blank" rel="noopener noreferrer">
          Open Warpcast Profile
        </a>
      </Menu.Item>
      <Menu.Item key="castFrame1">
        <a href={`https://warpcast.com/~/compose?text=${encodeURIComponent('Check out this Moxie frame: moxie.gobase.wtf ')}`} target="_blank" rel="noopener noreferrer">
          @basewtf.eth
        </a>
      </Menu.Item>
      <Menu.Item key="castFrame2">
        <a href={`https://warpcast.com/~/compose?text=${encodeURIComponent('View my Farcaster stats: moxiestatuscheck.basedcoder.link ')}`} target="_blank" rel="noopener noreferrer">
          @abss
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <Card
      title="Moxie Claims"
      style={{ marginBottom: 20, background: theme.secondary }}
      extra={
        <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
          <Button type="text" icon={<EllipsisOutlined />} />
        </Dropdown>
      }
    >
      <Row gutter={16}>
        <Col span={8}>
          <Statistic
            title={<Text style={{ color: theme.text }}>Available to Claim</Text>}
            value={moxieClaims.availableClaimAmount}
            precision={2}
            valueStyle={{ color: theme.highlight }}
            prefix="Ⓜ️"
            suffix={
              moxiePriceUSD ? (
                <Text style={{ fontSize: '0.8em', color: theme.textSecondary }}>
                  (≈ ${(moxieClaims.availableClaimAmount * moxiePriceUSD).toFixed(2)} USD)
                </Text>
              ) : ''
            }
          />
        </Col>
        <Col span={8}>
          <Statistic
            title={<Text style={{ color: theme.text }}>Claimed</Text>}
            value={moxieClaims.claimedAmount}
            precision={2}
            valueStyle={{ color: theme.highlight }}
            prefix="Ⓜ️"
            suffix={
              moxiePriceUSD ? (
                <Text style={{ fontSize: '0.8em', color: theme.textSecondary }}>
                  (≈ ${(moxieClaims.claimedAmount * moxiePriceUSD).toFixed(2)} USD)
                </Text>
              ) : ''
            }
          />
        </Col>
        <Col span={8}>
          <Statistic
            title={<Text style={{ color: theme.text }}>Processing</Text>}
            value={moxieClaims.processingAmount}
            precision={2}
            valueStyle={{ color: theme.highlight }}
            prefix="Ⓜ️"
            suffix={
              moxiePriceUSD ? (
                <Text style={{ fontSize: '0.8em', color: theme.textSecondary }}>
                  (≈ ${(moxieClaims.processingAmount * moxiePriceUSD).toFixed(2)} USD)
                </Text>
              ) : ''
            }
          />
        </Col>
      </Row>
    </Card>
  );
};

export default MoxieClaimsCard;