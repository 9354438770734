import React from 'react';
import { Card, List, Typography, Pagination, Button } from 'antd';
import { Trash2 } from 'lucide-react';

const { Text } = Typography;

const LogsCard = ({ logs, currentPage, setCurrentPage, clearLogs, theme }) => {
  const logsPerPage = 10;

  return (
    <Card
      title="Logs"
      style={{ marginTop: 20, background: theme.secondary }}
      extra={
        <Button
          icon={<Trash2 size={16} />}
          onClick={clearLogs}
          style={{
            background: 'transparent',
            border: 'none',
            color: theme.text,
          }}
        >
          Clear Logs
        </Button>
      }
    >
      <List
        size="small"
        dataSource={logs.slice((currentPage - 1) * logsPerPage, currentPage * logsPerPage)}
        renderItem={(log) => (
          <List.Item style={{ borderBottom: `1px solid ${theme.border}` }}>
            <Text style={{ color: theme.text }}>{log}</Text>
          </List.Item>
        )}
      />
      <Pagination
        current={currentPage}
        onChange={setCurrentPage}
        total={logs.length}
        pageSize={logsPerPage}
        style={{ marginTop: 16, textAlign: 'right' }}
        showSizeChanger={false}
      />
    </Card>
  );
};

export default LogsCard;