import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { Layout, Input, Typography, Button, Spin, message } from 'antd';
import {
  User,
  DollarSign,
  BarChart,
  Activity,
  Sun,
  Moon,
  TrendingUp,
  RefreshCw,
  Briefcase,
  Image,
  Hammer,
  LucideDice6,
} from 'lucide-react';
import { themeColors } from '../utils/themeColors';
import ProfileCard from './ProfileCard';
import FanTokensCard from './FanTokensCard';
import MoxieEarningsCard from './MoxieEarningsCard';
import MoxieClaimsCard from './MoxieClaimsCard';
import UpcomingAuctionsCard from './UpcomingAuctionsCard';
import FollowersCard from './FollowersCard';
import ReplykeMetricsCard from './ReplykeMetricsCard';
import LogsCard from './LogsCard';
import PortfolioCard from './PortfolioCard';
import FarcardsCard from './FarcardsCard';
import HamReportCard from './HamReportCard';
import DegenReportCard from './DegenReportCard';

const { Header, Content, Footer } = Layout;
const { Title, Text } = Typography;
const { Search } = Input;

const FarcasterLookup = () => {
  const [theme, setTheme] = useState('dark');
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedMenuItem, setSelectedMenuItem] = useState('profile');
  const [logs, setLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [duneData, setDuneData] = useState([]);
  const [loadingDuneData, setLoadingDuneData] = useState(false);
  const [moxieEarnings, setMoxieEarnings] = useState(null);
  const [moxieClaims, setMoxieClaims] = useState(null);
  const [upcomingAuctions, setUpcomingAuctions] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [loadingFollowers, setLoadingFollowers] = useState(false);
  const [replykeMetrics, setReplykeMetrics] = useState(null);
  const [moxiePriceUSD, setMoxiePriceUSD] = useState(null);
  const [portfolio, setPortfolio] = useState([]);
  const [loadingPortfolio, setLoadingPortfolio] = useState(false);
  const [farcards, setFarcards] = useState(null);
  const [loadingFarcards, setLoadingFarcards] = useState(false);
  const [farcardHolders, setFarcardHolders] = useState([]);
  const [loadingHolders, setLoadingHolders] = useState(false);
  const [connectedAddresses, setConnectedAddresses] = useState([]);

  const currentTheme = themeColors[theme];

  const addLog = useCallback((message) => {
    setLogs((prevLogs) => [...prevLogs, `${new Date().toISOString()}: ${message}`]);
  }, []);

  const fetchMoxiePrice = useCallback(async () => {
    try {
      const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=moxie&vs_currencies=usd');
      const price = response.data.moxie?.usd;
      if (price) {
        setMoxiePriceUSD(price);
        addLog(`Fetched Moxie price: Ⓜ️${price} USD`);
      } else {
        addLog('Moxie price not found in API response');
      }
    } catch (err) {
      addLog('Error fetching Moxie price');
      console.error('Moxie Price Error:', err);
    }
  }, [addLog]);

  const fetchProfileData = useCallback(async (searchValue) => {
    setLoading(true);
    setError(null);
    addLog(`Fetching profile data for: ${searchValue}`);
    try {
      const query = `
        query SearchProfile($userId: String!) {
          Socials(input: { filter: { profileName: { _eq: $userId } }, blockchain: ethereum }) {
            Social {
              profileBio
              profileHandle
              profileImage
              profileName
              userId
              followerCount
              followingCount
              farcasterScore {
                farRank
                farBoost
              }
              connectedAddresses {
                address
              }
            }
          }
        }
      `;
      const variables = { userId: searchValue };
      const response = await axios.post(
        'https://api.airstack.xyz/gql',
        { query, variables },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_AIRSTACK_API_KEY}`,
          },
        }
      );

      const socialData = response.data.data?.Socials?.Social?.[0];
      if (socialData) {
        setProfileData(socialData);
        setConnectedAddresses(socialData.connectedAddresses.map(addr => addr.address));
        addLog('Profile data fetched successfully');
        return socialData;
      } else {
        throw new Error('No valid profile data returned');
      }
    } catch (err) {
      const errorMessage = err.response?.data?.error || err.message;
      setError(`Error fetching profile data: ${errorMessage}`);
      addLog(`Error fetching profile data: ${errorMessage}`);
      console.error('Profile Fetch Error:', err);
      return null;
    } finally {
      setLoading(false);
    }
  }, [addLog]);

  const fetchMoxieEarnings = useCallback(async (userId) => {
    setLoading(true);
    addLog('Fetching Moxie Earnings');
    try {
      const query = `
        query MoxieEarnings($userId: String!) {
          today: FarcasterMoxieEarningStats(
            input: {
              filter: { entityId: { _eq: $userId }, entityType: { _eq: USER } },
              timeframe: TODAY,
              blockchain: ALL
            }
          ) {
            FarcasterMoxieEarningStat {
              allEarningsAmount
            }
          }
          weekly: FarcasterMoxieEarningStats(
            input: {
              filter: { entityId: { _eq: $userId }, entityType: { _eq: USER } },
              timeframe: WEEKLY,
              blockchain: ALL
            }
          ) {
            FarcasterMoxieEarningStat {
              allEarningsAmount
            }
          }
          lifetime: FarcasterMoxieEarningStats(
            input: {
              filter: { entityId: { _eq: $userId }, entityType: { _eq: USER } },
              timeframe: LIFETIME,
              blockchain: ALL
            }
          ) {
            FarcasterMoxieEarningStat {
              allEarningsAmount
            }
          }
        }
      `;
      const variables = { userId: userId.toString() };
      const response = await axios.post('https://api.airstack.xyz/gql', { query, variables }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_AIRSTACK_API_KEY}`,
        },
      });

      const earningsData = {
        today: response.data.data?.today?.FarcasterMoxieEarningStat?.[0] || null,
        weekly: response.data.data?.weekly?.FarcasterMoxieEarningStat?.[0] || null,
        lifetime: response.data.data?.lifetime?.FarcasterMoxieEarningStat?.[0] || null,
      };

      setMoxieEarnings(earningsData);
      addLog('Moxie Earnings data fetched successfully');
    } catch (err) {
      const errorMessage = err.response?.data?.errors?.[0]?.message || err.message;
      setError(`Error fetching Moxie Earnings: ${errorMessage}`);
      addLog(`Error fetching Moxie Earnings: ${errorMessage}`);
      console.error('Moxie Earnings Error:', err.response?.data || err);
    } finally {
      setLoading(false);
    }
  }, [addLog]);

  const fetchMoxieClaims = useCallback(async (userId) => {
    setLoading(true);
    addLog(`Fetching Moxie Claims for userId: ${userId}`);
    try {
      const query = `
        query MoxieClaimDetails($userId: String!) {
          FarcasterMoxieClaimDetails(
            input: { blockchain: ALL, filter: { fid: { _eq: $userId } } }
          ) {
            FarcasterMoxieClaimDetails {
              availableClaimAmount
              claimedAmount
              processingAmount
            }
          }
        }
      `;

      const variables = { userId: userId.toString() };
      const response = await axios.post('https://api.airstack.xyz/gql', { query, variables }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_AIRSTACK_API_KEY}`,
        },
      });

      const claimsData = response.data.data?.FarcasterMoxieClaimDetails?.FarcasterMoxieClaimDetails?.[0];

      if (claimsData) {
        setMoxieClaims(claimsData);
        addLog('Moxie Claims data fetched successfully');
      } else {
        addLog('No data returned for Moxie Claims');
        setMoxieClaims(null);
      }
    } catch (err) {
      const errorMessage = err.response?.data?.errors?.[0]?.message || err.message;
      setError(`Error fetching Moxie Claims: ${errorMessage}`);
      addLog(`Error fetching Moxie Claims: ${errorMessage}`);
      console.error('Moxie Claims Error:', err.response?.data || err);
      setMoxieClaims(null);
    } finally {
      setLoading(false);
    }
  }, [addLog]);

  const fetchFollowers = useCallback(async (userId) => {
    setLoadingFollowers(true);
    try {
      const query = `
        query GetFollowers($profileId: String!) {
          SocialFollowers(
            input: {filter: {followingProfileId: {_eq: $profileId}}, blockchain: ALL, limit: 200}
          ) {
            Follower {
              followerProfileId
              followerSince
              followerAddress {
                identity
                addresses
              }
            }
          }
        }
      `;
      const variables = { profileId: userId };
      const response = await axios.post('https://api.airstack.xyz/gql', { query, variables }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_AIRSTACK_API_KEY}`,
        },
      });

      const followersData = response.data.data?.SocialFollowers?.Follower || [];

      const processedFollowers = followersData.map((follower, index) => ({
        key: index,
        followerProfileId: follower.followerProfileId,
        followerSince: follower.followerSince,
        identity: follower.followerAddress.identity,
        addresses: follower.followerAddress.addresses,
      }));

      setFollowers(processedFollowers);
      addLog(`Fetched ${processedFollowers.length} followers`);
    } catch (err) {
      console.error('Followers Fetch Error:', err);
      message.error('Failed to fetch followers. Please try again.');
    } finally {
      setLoadingFollowers(false);
    }
  }, [addLog]);

  const fetchDuneData = useCallback(async (userId) => {
    setLoadingDuneData(true);
    addLog('Fetching Dune Analytics data');
    
    const DUNE_API_KEY = process.env.REACT_APP_DUNE_API_KEY;
    const DUNE_QUERY_ID = process.env.REACT_APP_DUNE_QUERY_ID;
  
    if (!DUNE_API_KEY || !DUNE_QUERY_ID) {
      console.error('Dune API key or Query ID is not set');
      message.error('Dune Analytics configuration is incomplete. Please check your environment variables.');
      addLog('Error: Dune Analytics configuration is incomplete');
      setLoadingDuneData(false);
      return;
    }
  
    try {
      console.log('Submitting Dune query:', DUNE_QUERY_ID);
      // Step 1: Submit the query execution
      const submitResponse = await axios.post(
        `https://api.dune.com/api/v1/query/${DUNE_QUERY_ID}/execute`,
        { parameters: { fid: userId } },
        { 
          headers: { 
            'x-dune-api-key': DUNE_API_KEY,
            'Content-Type': 'application/json'
          } 
        }
      );
  
      if (!submitResponse.data.execution_id) {
        throw new Error('No execution ID received from Dune API');
      }
  
      const executionId = submitResponse.data.execution_id;
      console.log('Received execution ID:', executionId);
  
      // Step 2: Poll for query execution status
      let statusResponse;
      do {
        await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 2 seconds
        statusResponse = await axios.get(
          `https://api.dune.com/api/v1/execution/${executionId}/status`,
          { headers: { 'x-dune-api-key': DUNE_API_KEY } }
        );
        console.log('Query execution status:', statusResponse.data.state);
      } while (statusResponse.data.state !== 'QUERY_STATE_COMPLETED');
  
      // Step 3: Fetch the results
      const resultsResponse = await axios.get(
        `https://api.dune.com/api/v1/execution/${executionId}/results`,
        { headers: { 'x-dune-api-key': DUNE_API_KEY } }
      );
  
      const duneTableData = resultsResponse.data.result.rows;
      setDuneData(duneTableData);
      addLog(`Fetched ${duneTableData.length} rows of fan token data`);
    } catch (err) {
      console.error('Dune Analytics Error:', err);
      let errorMessage = 'Failed to fetch fan tokens. Please try again later.';
      if (err.response) {
        console.error('Error response:', err.response.data);
        errorMessage += ` Error: ${err.response.data.error || err.message}`;
      }
      message.error(errorMessage);
      addLog(`Error fetching Dune data: ${errorMessage}`);
    } finally {
      setLoadingDuneData(false);
    }
  }, [addLog]);

  const fetchUpcomingAuctions = useCallback(async () => {
    setLoading(true);
    addLog('Fetching upcoming auctions');
    try {
      const query = `
        query UpcomingAuctions {
          FarcasterFanTokenAuctions(
            input: {
              filter: {status: {_eq: UPCOMING}, entityType: {_eq: USER}},
              blockchain: ALL,
              limit: 200,
              order: {estimatedEndTimestamp: ASC}
            }
          ) {
            FarcasterFanTokenAuction {
              auctionId
              auctionSupply
              entityId
              entityName
              entitySymbol
              entityType
              estimatedEndTimestamp
              estimatedStartTimestamp
              rewardDistributionPercentage {
                channelFans
                creator
                creatorFans
                network
              }
            }
          }
        }
      `;

      const response = await axios.post('https://api.airstack.xyz/gql', { query }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_AIRSTACK_API_KEY}`,
        },
      });

      if (response.data && response.data.data && response.data.data.FarcasterFanTokenAuctions) {
        const auctions = response.data.data.FarcasterFanTokenAuctions.FarcasterFanTokenAuction;
        setUpcomingAuctions(auctions);
        addLog(`Fetched ${auctions.length} upcoming auctions`);
      } else {
        throw new Error('Invalid response structure from FarcasterFanTokenAuctions API');
      }
    } catch (err) {
      const errorMessage = err.response?.data?.error || err.message;
      setError(`Error fetching upcoming auctions: ${errorMessage}`);
      addLog(`Error fetching upcoming auctions: ${errorMessage}`);
      console.error('Auction Fetch Error:', err);
      message.error('Failed to fetch upcoming auctions. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [addLog]);

  const fetchReplykeMetrics = useCallback(async (profileName) => {
    addLog(`Fetching Replyke metrics for profile: ${profileName}`);
    try {
      const query = `
        query GetFarBoost($profileName: String!) {
          Socials(input: { filter: { profileName:{ _eq: $profileName } }, blockchain: ethereum }) {
            Social {
              farcasterScore {
                farBoost
              }
            }
          }
        }
      `;
      const variables = { profileName };
      const response = await axios.post('https://api.airstack.xyz/gql', { query, variables }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_AIRSTACK_API_KEY}`,
        },
      });

      const farBoost = response.data.data?.Socials?.Social?.[0]?.farcasterScore?.farBoost;

      if (farBoost) {
        const likeReward = farBoost * 0.5;
        const replyReward = farBoost * 1;
        const recastReward = farBoost * 2;
        const totalReward = likeReward + replyReward + recastReward;
        const replykeMetrics = {
          farBoost: farBoost,
          likeReward: likeReward,
          replyReward: replyReward,
          recastReward: recastReward,
          totalReward: totalReward,
        };

        setReplykeMetrics(replykeMetrics);
        addLog('Replyke metrics calculated successfully');
      } else {
        addLog('FarBoost not found for the user');
      }
    } catch (err) {
      const errorMessage = err.response?.data?.error || err.message;
      setError(`Error fetching Replyke metrics: ${errorMessage}`);
      addLog(`Error fetching Replyke metrics: ${errorMessage}`);
      console.error('Replyke Metrics Error:', err);
    }
  }, [addLog, setError]);

  const fetchPortfolio = useCallback(async (fid) => {
    setLoadingPortfolio(true);
    try {
      const query = `
        query GetPortfolio($fid: String!) {
          MoxieUserPortfolios(input: {filter: {fid: {_eq: $fid}}}) {
            MoxieUserPortfolio {
              currentPrice
              fanTokenAddress
              fanTokenName
              lockedTvl
              protocolTokenInvested
              tokenLockedTvl
              tokenUnlockedTvl
              totalLockedAmount
              totalUnlockedAmount
              unlockedTvl
              walletAddresses
            }
          }
        }
      `;
      const variables = { fid };
      const response = await axios.post(
        'https://api.airstack.xyz/gql',
        { query, variables },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_AIRSTACK_API_KEY}`,
          },
        }
      );
      const portfolioData = response.data.data?.MoxieUserPortfolios?.MoxieUserPortfolio || [];
      setPortfolio(portfolioData);
      addLog(`Fetched ${portfolioData.length} fan tokens for the portfolio`);
    } catch (err) {
      console.error('Portfolio Fetch Error:', err);
      message.error('Failed to fetch portfolio data. Please try again.');
    } finally {
      setLoadingPortfolio(false);
    }
  }, [addLog]);

  const fetchFarcards = useCallback(async (userId) => {
    setLoadingFarcards(true);
    addLog(`Fetching Farcards for user ID: ${userId}`);
    try {
      const [userResponse, holdersResponse] = await Promise.all([
        axios.get(`https://far.cards/api/user/${userId}`),
        axios.get(`https://far.cards/api/holders/${userId}`)
      ]);
      
      const farcardsData = userResponse.data;
      const holdersData = holdersResponse.data;
      
      setFarcards(farcardsData);
      setFarcardHolders(holdersData);
      
      addLog(`Fetched Farcards data for user ${farcardsData.user.fname}`);
      addLog(`Fetched Farcard holders data for ${holdersData.username}`);
    } catch (err) {
      console.error('Farcards Fetch Error:', err);
      message.error('Failed to fetch Farcards. Please try again.');
    } finally {
      setLoadingFarcards(false);
    }
  }, [addLog]);

  const fetchAllData = useCallback(async (searchValue) => {
    setLoading(true);
    setError(null);
    addLog(`Starting data fetch for: ${searchValue}`);

    try {
      const profile = await fetchProfileData(searchValue);
      if (!profile) {
        throw new Error('Failed to fetch profile data');
      }

      await Promise.all([
        fetchFollowers(profile.userId),
        fetchReplykeMetrics(profile.profileName),
        fetchMoxieEarnings(profile.userId),
        fetchMoxieClaims(profile.userId),
        fetchDuneData(profile.userId),
        fetchUpcomingAuctions(),
        fetchPortfolio(profile.userId),
        fetchFarcards(profile.userId),
      ]);

      addLog('All data fetched successfully');
    } catch (err) {
      setError(`Error fetching data: ${err.message}`);
      addLog(`Error during data fetch: ${err.message}`);
      console.error('Data Fetch Error:', err);
    } finally {
      setLoading(false);
    }
  }, [fetchProfileData, fetchFollowers, fetchReplykeMetrics, fetchMoxieEarnings, fetchMoxieClaims, fetchDuneData, fetchUpcomingAuctions, fetchPortfolio, fetchFarcards, addLog]);

  const handleSearch = useCallback((value) => {
    const trimmedValue = value.trim();
    if (trimmedValue) {
      fetchAllData(trimmedValue);
      setSelectedMenuItem('profile');
    } else {
      addLog('Please enter a valid Farcaster Name to search.');
      setError('Please enter a valid Farcaster Name to search.');
    }
  }, [fetchAllData, addLog]);

  const toggleTheme = useCallback(() => {
    setTheme((prevTheme) => (prevTheme === 'dark' ? 'light' : 'dark'));
  }, []);

  const handleMenuClick = useCallback((key) => {
    setSelectedMenuItem(key);
  }, []);

  const clearLogs = useCallback(() => {
    setLogs([]);
    addLog('Logs cleared');
  }, [addLog]);

  useEffect(() => {
    fetchMoxiePrice();
    const interval = setInterval(fetchMoxiePrice, 60000); // Fetch every minute
    return () => clearInterval(interval);
  }, [fetchMoxiePrice]);

  const menuItems = [
    { key: 'profile', icon: <User size={16} />, label: 'Profile' },
    { key: 'fanTokens', icon: <DollarSign size={16} />, label: 'Fan Tokens' },
    { key: 'moxieEarnings', icon: <DollarSign size={16} />, label: 'Moxie Earnings' },
    { key: 'moxieClaims', icon: <DollarSign size={16} />, label: 'Moxie Claims' },
    { key: 'upcomingAuctions', icon: <BarChart size={16} />, label: 'Upcoming Auctions' },
    { key: 'followers', icon: <User size={16} />, label: 'Followers' },
    { key: 'replykeMetrics', icon: <TrendingUp size={16} />, label: 'Replyke Metrics' },
    { key: 'portfolio', icon: <Briefcase size={16} />, label: 'Portfolio' },
    { key: 'farcards', icon: <Image size={16} />, label: 'Farcards' },
    { key: 'hamReport', icon: <Hammer size={16} />, label: 'TN100x/HAM' },
    { key: 'degenReport', icon: <LucideDice6 size={16} />, label: 'Degen Report' },
    { key: 'logs', icon: <Activity size={16} />, label: 'Logs' },
  ];

  return (
    <Layout style={{ minHeight: '100vh', background: currentTheme.primary }}>
      <Header style={{ background: '#000000', padding: '0 16px' }}>
        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <img
            src="/header.png"
            alt="Header"
            style={{ width: 'auto', height: '60px', marginRight: '20px' }}
          />
          <div style={{ flexGrow: 1 }} />
          <img
            src="/moxielogo.png"
            alt="Moxie Logo"
            style={{ width: '50px', height: 'auto', marginRight: '20px' }}
          />
          <Button
            icon={theme === 'dark' ? <Sun size={16} /> : <Moon size={16} />}
            onClick={toggleTheme}
          />
        </div>
      </Header>

      <Content style={{ margin: '24px 16px 0', background: currentTheme.primary }}>
        <div style={{ padding: 24, minHeight: 360 }}>
          <Search
            placeholder="Enter Farcaster Name to search..."
            enterButton="Search"
            size="large"
            onSearch={handleSearch}
            style={{ marginBottom: 20, maxWidth: 600 }}
          />

          {profileData && (
            <div style={{ marginBottom: 20, display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
              {menuItems.map((item) => (
                <Button
                  key={item.key}
                  type={selectedMenuItem === item.key ? 'primary' : 'default'}
                  icon={item.icon}
                  onClick={() => handleMenuClick(item.key)}
                >
                  {item.label}
                </Button>
              ))}
            </div>
          )}

          {loading && (
            <div style={{ textAlign: 'center', padding: '50px 0' }}>
              <Spin size="large" />
            </div>
          )}

          {error && (
            <Text type="danger">{error}</Text>
          )}

          {selectedMenuItem === 'profile' && profileData && (
            <ProfileCard profileData={profileData} theme={currentTheme} />
          )}

          {selectedMenuItem === 'fanTokens' && (
            <FanTokensCard 
              duneData={duneData} 
              loadingDuneData={loadingDuneData} 
              theme={currentTheme} 
              fetchDuneData={() => fetchDuneData(profileData?.userId)}
              profileHandle={profileData?.profileHandle}
            />
          )}

          {selectedMenuItem === 'moxieEarnings' && moxieEarnings && (
            <MoxieEarningsCard 
              moxieEarnings={moxieEarnings} 
              moxiePriceUSD={moxiePriceUSD} 
              theme={currentTheme}
              profileHandle={profileData?.profileHandle}
            />
          )}

          {selectedMenuItem === 'moxieClaims' && moxieClaims && (
            <MoxieClaimsCard 
              moxieClaims={moxieClaims} 
              moxiePriceUSD={moxiePriceUSD} 
              theme={currentTheme} 
              profileData={profileData}
            />
          )}

          {selectedMenuItem === 'upcomingAuctions' && (
            <UpcomingAuctionsCard 
              upcomingAuctions={upcomingAuctions} 
              loading={loading} 
              theme={currentTheme}
              profileHandle={profileData?.profileHandle}
            />
          )}

          {selectedMenuItem === 'followers' && (
            <FollowersCard 
              followers={followers} 
              loading={loadingFollowers} 
              theme={currentTheme}
              profileHandle={profileData?.profileHandle}
            />
          )}

          {selectedMenuItem === 'replykeMetrics' && replykeMetrics && (
            <ReplykeMetricsCard 
              replykeMetrics={replykeMetrics} 
              moxiePriceUSD={moxiePriceUSD} 
              theme={currentTheme}
              profileHandle={profileData?.profileHandle}
            />
          )}

          {selectedMenuItem === 'portfolio' && (
            <PortfolioCard 
              portfolio={portfolio}
              loading={loadingPortfolio}
              theme={currentTheme}
              profileHandle={profileData?.profileHandle}
            />
          )}

          {selectedMenuItem === 'farcards' && (
            <FarcardsCard 
              farcards={farcards}
              farcardHolders={farcardHolders}
              loading={loadingFarcards}
              loadingHolders={loadingHolders}
              theme={currentTheme}
              profileHandle={profileData?.profileHandle}
            />
          )}

          {selectedMenuItem === 'hamReport' && profileData && (
            <HamReportCard 
              fid={profileData.userId}
              theme={currentTheme}
              profileHandle={profileData.profileHandle}
            />
          )}

          {selectedMenuItem === 'degenReport' && profileData && (
            <DegenReportCard 
              fid={profileData.userId}
              connectedAddresses={connectedAddresses}
              theme={currentTheme}
              profileHandle={profileData.profileHandle}
            />
          )}

          {selectedMenuItem === 'logs' && (
            <LogsCard 
              logs={logs} 
              currentPage={currentPage} 
              setCurrentPage={setCurrentPage} 
              clearLogs={clearLogs} 
              theme={currentTheme} 
            />
          )}
        </div>
      </Content>

      <Footer 
        style={{ 
          background: '#000000', 
          padding: '10px 16px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text style={{ color: '#ffffff', marginRight: '10px' }}>
            Farcaster Lookup ©2024 shyguy, LLC
          </Text>
          <img
            src="/nexid-logo-64px.png"
            alt="NexID Logo"
            style={{ width: '32px', height: '32px' }}
          />
        </div>
      </Footer>
    </Layout>
  );
};

export default FarcasterLookup;